import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { Button, ButtonSkin } from '@voyagers/button';
import { Banner, BannerSkin } from '@voyagers/banner';
import { rest } from 'js/network/apiConfig';
import { Toast } from '@swingvy/design-system';
import { Field } from '@voyagers/form';
import { styled } from '@voyagers/theme';
import { AlertDialog } from '@voyagers/dialog';
import { IcGoogle } from '@voyagers/icons';
import { LocationUtil } from '@swingvy/frontend-util';

import { useSchema } from 'js/hooks/useSchema';
import RoutePath from 'js/app/RoutePath';
import { loginSchema } from 'js/components/login/schema';
import { refreshTokenAndLogin } from 'js/app/view/login/LoginPage';

export const StyledLink = styled.a`
    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
`;
const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px auto 0;
    width: 283px;
`;
const SingleSignInButtonTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    svg {
        path:first-child {
            fill: #4285f4;
        }
        path: nth-child(2) {
            fill: #34a853;
        }
        path: nth-child(3) {
            fill: #fbbc05;
        }
        path: last-child {
            fill: #ea4335;
        }
    }
    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
`;
const Divider = styled.div`
    padding-top: 30px;
    border-top: none;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor.divider};
`;
const OrText = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
`;

type LoginFormValues = {
    email: string;
    password: string;
};

const getBrowserName = () => {
    const test = (regexp) => regexp.test(navigator.userAgent);
    if (test(/edg/i)) return 'Microsoft Edge';
    if (test(/chrome|chromium|crios/i)) return 'Google Chrome';
    if (test(/firefox|fxios/i)) return 'Mozilla Firefox';
    if (test(/safari/i)) return 'Apple Safari';
    if (test(/trident/i)) return 'Microsoft Internet Explorer';
    return 'Unknown browser';
};

const LoginPanel = ({ options, setStage, setLoginData }) => {
    const { t } = useTranslation();
    const [isLoadingOnSignIn, setIsLoadingOnSignIn] = useState(false);
    const [showVerifyEmailDialog, setShowVerifyEmailDialog] = useState(false);
    const [errorBanner, setErrorBanner] = useState<{ title?: string; content?: string }>({});
    const [isLoadingOnGoogleSignIn, setIsLoadingOnGoogleSignIn] = useState(false);

    const errorCode = LocationUtil.getParamValue('errorCode', document.location.href);
    const errorMessage = LocationUtil.getParamValue('errorMessage', document.location.href);
    const verifyEmail = LocationUtil.getParamValue('verifyEmail', document.location.href);

    useEffect(() => {
        if (!errorCode) return;

        switch (errorCode) {
            case '606':
                setShowVerifyEmailDialog(true);
                break;
            case '611':
                setErrorBanner({ title: errorMessage });
                break;
            default:
                break;
        }
    }, [errorCode]);

    useEffect(() => {
        if (options.unknownerror) {
            setErrorBanner({
                title: t('login.msg_unknownerror.title'),
                content: t('login.msg_unknownerror.message'),
            });
        }
    }, []);

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: useSchema(loginSchema),
        defaultValues: { email: '', password: '' },
    });
    const onSubmit: SubmitHandler<LoginFormValues> = async ({ email, password }) => {
        setIsLoadingOnSignIn(true);

        Cookies.remove('sst', { path: '/api' });
        let loginIdentifier = localStorage.getItem('login_identifier');
        if (!loginIdentifier) {
            loginIdentifier = uuidv4();
            localStorage.setItem('login_identifier', loginIdentifier);
        }
        const loginData = {
            email: email.trim(),
            password,
            device: {
                name: getBrowserName(),
                identifier: loginIdentifier,
            },
        };
        try {
            const { data: result } = await rest.session.requestLogin(loginData);
            await refreshTokenAndLogin(result);
        } catch (error) {
            setValue('password', '');

            const { code, message, data } = error;
            switch (code) {
                case 601: // when user is terminated during the login process
                    const { origin } = document.location;
                    document.location.href = `${RoutePath.LOGOUT}?redirect=${origin}`;
                    break;
                case 606: // hasn't completed email verification
                    setShowVerifyEmailDialog(true);
                    break;
                case 608: // before completing onboarding process
                    window.location.href = error.data?.location;
                    window.location.reload();
                    break;
                case 611: // before the invitation date
                case 615: // invalid email or password
                    setErrorBanner({ title: message });
                    break;
                case 616: // MFA
                    setStage('verify');
                    setLoginData({ ...loginData, trustDeviceEnabled: data?.trustDeviceEnabled });
                    break;
                default:
                    setErrorBanner({ title: message });
                    Toast.Builder('error')
                        .setHeader(t('login.msg_ooops'))
                        .setBody(t('login.msg_temp_network_error'))
                        .build()
                        .show();
            }
        } finally {
            setIsLoadingOnSignIn(false);
        }
    };

    return (
        <div className="main-container _main_container">
            <div className="row main-inner-container">
                <div className="content-box col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                    <div className="content-inner-box login-view">
                        <div className="welcome-title">{t('login.welcome_title')}</div>
                        <p className="text-muted guide-signup">
                            {t('login.register_guide_text')}{' '}
                            <StyledLink
                                className=" _register swv-btn swv-btn-links swv-theme-light register-link"
                                href={RoutePath.SWINGVY_LANDING.DEMO}
                            >
                                {t('login.register_page_link')}
                            </StyledLink>
                        </p>
                        <Banner
                            skin={BannerSkin.Error}
                            title={errorBanner.title}
                            showCloseBtn={false}
                            show={Boolean(errorBanner.title)}
                            style={{ marginBottom: 20 }}
                        >
                            {errorBanner.content}
                        </Banner>
                        <div className="swv-input-box">
                            <div className="swv-input-group">
                                <Field htmlFor="email" error={t(errors.email?.message)}>
                                    <input
                                        {...register('email')}
                                        type="text"
                                        className="swv-input"
                                        placeholder={t('form.email.placeholder')}
                                    />
                                </Field>
                            </div>
                        </div>
                        <div className="swv-input-box">
                            <div className="swv-input-group">
                                <Field htmlFor="password" error={t(errors.password?.message)}>
                                    <input
                                        {...register('password')}
                                        type="password"
                                        className="swv-input"
                                        placeholder={t('form.password.label')}
                                    />
                                </Field>
                                <StyledLink
                                    href="#/etc/forgotPassword"
                                    className="forgot-text pull-right swv-btn swv-btn-links swv-theme-light"
                                >
                                    {t('login.forgot_password_text')}
                                </StyledLink>
                            </div>
                        </div>
                        <ButtonGroup>
                            <Button
                                skin={ButtonSkin.Primary}
                                size="large"
                                style={{ width: 283 }}
                                onClick={handleSubmit(onSubmit)}
                                loading={isLoadingOnSignIn}
                                disabled={isLoadingOnGoogleSignIn}
                            >
                                {t('login.login_button.title')}
                            </Button>
                            <Divider />
                            <OrText>{t('login.sign_in_with_google.or')}</OrText>
                            <Button
                                skin={ButtonSkin.Basic}
                                size="large"
                                style={{ width: 283 }}
                                onClick={async () => {
                                    try {
                                        setIsLoadingOnGoogleSignIn(true);
                                        const { data: response } =
                                            await rest.session.getGoogleSignInUrl();
                                        document.location.href = response.data.url;
                                    } catch (error) {
                                        Toast.Builder('error')
                                            .setHeader(t('common.oops'))
                                            .setBody(t('error.msg_unknown_error'))
                                            .build()
                                            .show();
                                    } finally {
                                        setIsLoadingOnGoogleSignIn(false);
                                    }
                                }}
                                loading={isLoadingOnGoogleSignIn}
                                disabled={isLoadingOnSignIn}
                            >
                                <SingleSignInButtonTitle>
                                    <IcGoogle />
                                    <span>{t('login.sign_in_with_google.title')}</span>
                                </SingleSignInButtonTitle>
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            <AlertDialog
                title={t('verify_email_modal.login.title')}
                destructive
                open={showVerifyEmailDialog}
                onDismiss={() => setShowVerifyEmailDialog(false)}
                primaryButton={{
                    label: t('Okay'),
                    skin: ButtonSkin.Basic,
                    onClick: () => setShowVerifyEmailDialog(false),
                }}
            >
                <div>
                    {t('verify_email_modal.login.msg_account_not_activated')}
                    <br />
                    {t('verify_email_modal.login.msg_verify_email')}
                    <br />
                    <br />
                    <Button
                        skin={ButtonSkin.TransparentPrimary}
                        onClick={async () => {
                            const email = getValues('email') || verifyEmail;

                            try {
                                await rest.session.sendVerificationEmail({ email });
                                Toast.Builder('success')
                                    .setHeader(t('verify_email_modal.msg_new_email_sent'))
                                    .build()
                                    .show();
                            } catch (error) {
                                Toast.Builder('error')
                                    .setHeader(t('login.msg_ooops'))
                                    .setBody(t('login.msg_temp_network_error'))
                                    .build()
                                    .show();
                            } finally {
                                setShowVerifyEmailDialog(false);
                            }
                        }}
                    >
                        {t('verify_email_modal.login.msg_resend_mail.click_here')}
                    </Button>{' '}
                    {t('verify_email_modal.login.msg_resend_mail.to_get_new_email')}
                </div>
            </AlertDialog>
        </div>
    );
};

export default LoginPanel;
